<template>
  <GModal name="modal" class="modal modal-confirm-remove-points">
    <div slot="header">
      <div class="modal-title">
        <div class="text-red">Batal tukar SOCO Point?</div>
        <div class="text">
          <p>
            kamu bisa dapat <b>DISKON {{ point_applied | currencyRp }}</b>
            <br />
            dari tukar SOCO Point. Tetap batalkan?
          </p>
        </div>
      </div>
    </div>
    <div slot="body">
      <div class="confirmed-cancel-point" @click="cancelPointRedeem">
        <span>Ya, batalkan penukaran</span>
      </div>
      <div class="continue-redeem" @click="$emit('close')">
        <span>Batalkan</span>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalConfirmRemovePoints',
  computed: {
    point_applied() {
      return this.$store.state.vmCola.point_applied || 0;
    },
  },
  methods: {
    cancelPointRedeem() {
      this.$emit('cancel-point');
    },
  },
};
</script>
